.title-table-notes {
  display: flex;
  align-items: start;
  background-color: white;
  border-radius: 8px;
  padding: 10px 10px;
  margin-bottom: 20px;
}

.title-table-notes h3 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.title-table-notes p {
  font-size: small;
  color: gray;
}

.title-table-notes-expanded {
  display: flex;
}

.btn-title-container {
  display: flex;
  align-items: center;
}

.title-container-expandable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save-form {
  margin-left: 20px;
}

.btn-line-bottom-form{
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.btn-send-bottom{
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .title-table-notes {
    margin-top: 20px;
  }

  .btn-title-container{
    flex-direction: column;

  }

  .btn-send-form{
    margin-top: 15px;
  }

  .btn-line-bottom-form{

    flex-direction: column;
    align-items: stretch;

  }

  .btn-send-bottom{
  margin-top: 10px;
    margin-left: 0px;
  }
}
