.client-recommendation-page-container {
  /* width: 50%; */
  margin: auto;
  max-height: 100vh;
  overflow: auto;
  
  

}

.recommendation-appbar {
  background-color: rgb(164, 255, 216);
  padding: 20px;
}

.recommendation-section {
  margin-bottom: 20px;
}

.recommendation-title-container {
  background-color: rgb(240, 240, 240);
  padding: 20px 10px;
}

/* .client-recommendation-page-container{
    padding: 10px;
} */

.client-recommendation-page-container h2,
h3 {
  margin: 0px;
}

.client-recommendation-page-container i {
  margin-right: 10px;
  color: #367af6;
}

.recommendation-container {
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 6px;
  padding: 20px 10px;
  width: 80%;
}

.supplement-container{
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 6px;
  padding: 20px 10px;
  width: 80%;
  margin-bottom: 50px;
  margin-top: 30px;
}

.supplement-weeks-container{
  margin: 30px 10px
}

.recommendation-response {
  margin-top: 10px;
}

.supplement-img {
  height: 70px;
  width: auto;
}

.supplement-name {
  color: #367af6;
}

.supplement-name:hover {
  color: green;
  cursor: pointer;
}

.supplement-spec {
  margin: 10px 0px;
  display: flex;
}

.supp-spec-details {
  margin-left: 20px;
}
.supp-spec-details span{
  font-size: smaller;
  color: gray;
}

.supp-spec-details p{
  margin: 10px 0px;
}

@media screen and (max-width: 768px) {
  .client-recommendation-page-container {
    width: 100%;
  }

  .recommendation-container {
    width: auto;
  }
}
