.client-mainpage-container {
  max-height: 100vh;
  width: 100%;

  overflow: auto;
}

.home-container {
  padding: 20px;
  
}

.home-container-content{
  padding: 20px;
    background: rgba( 255, 255, 255, 0.35 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 6px );
  -webkit-backdrop-filter: blur( 6px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.consultation-item-client-container {
  background-color: white;
  margin: 10px 0px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.consultation-item-client-container h4,
p {
  margin: 0px;
}



.consultation-btn-container {
  flex-direction: column;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .client-mainpage-container {
    width: 100%;
    /* padding: 10px; */
  }

  .consultation-item-client-container {
    width: 90%;
  }
}

.message-consultations p{
  margin: 20px 10px;
}

.btn-telegram-container{
 display: flex;
 justify-content: center;
}