
.title-consultation-list{
  margin-bottom: 20px;
}

.title-consultation-list h3{
  margin-bottom: 10px;
}

.title-consultation-list p{
  font-size: smaller;
  color: gray;
}


.consultation-btn-container a {
  text-decoration: none !important;
}

.consultation-item-title{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.consultation-item-title i{
  color: #367af6;
  margin-right: 5px;
}

.sub-label{
  margin-bottom: 10px;
}


.sub-label span{
  margin: 5px 0px;
  font-size: normal;
  color: gray;
  font-weight: normal;
}

/*TABLE*/

/* UserTable.css */
/* Table header styles */

.table-container {
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.table-users {
  border-radius: 8px;
  padding: 3px;
}

.table-header {
  background-color: #dcdbdb; /* Gray background color */
  padding: 8px; /* Padding for header cells */
  text-align: left;
  border: 1px solid #ccc;
  /* Align header text to the left */
}

/* Table data (cell) styles */
.table-cell {
  border: 1px solid #ccc; /* Gray border around cells */
  padding: 8px; /* Padding for data cells */
  text-align: left;
  background-color: white; /* Align cell text to the left */
}

/* Add spacing between columns */
.table-cell + .table-cell {
  border-left: 1px solid #ccc; /* Gray line between columns */
}

.link-recommendation {
  text-decoration: none ;
}

.button-cell{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.button-cell i {
  margin-left: 10px;
}

