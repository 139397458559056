.login-page {
  background-color: rgb(239, 239, 239);
}

.login-container {
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.login-container h2 {
  text-align: center;
}

.login-container input {
  border: 2px solid transparent;
  width: 80%;
  height: 2.5em;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #f3f3f3;
  border-radius: 10px;
  transition: all 0.5s;
  margin-bottom: 20px;
}

.login-container input:hover,
.login-container input:focus {
  border: 2px solid #4a9dec;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}

.row-btn-login {
  display: flex;
  justify-content: center;
}

.btn-login {
  width: 200px;
}

.img-login{
  width: 50%;
  border-radius: 10px;
}

.input-password{
  margin-bottom: 5px !important;
}



.input-password-container {
  text-align: end;
  margin-right: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
  
}

.input-password-container p{
  font-size: small;
  color: #4a9dec;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
}

.input-password-container i{
  font-size: smaller;
  color: #4a9dec;
}