/* AddClientModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: auto;
    margin: 0px 20px;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content h2{
    text-align: center;
  }
  
  .modal input {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .add-client-modal i{
    margin-right: 10px;
    color:#367AF6;
   
  }
 

  .add-client-modal input {
    border: 2px solid transparent;
    width: 80%;
    height: 2.5em;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
  }


  
  .add-client-modal input:hover,
  .add-client-modal input:focus {
    border: 2px solid #4A9DEC;
    box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
    background-color: white;
  }
  
  .add-client-modal textarea{
    width: 100%;
  }

  /* MODIFY PROFILE */

  .input-edit-profile{
    display: flex;
    flex-direction: column;
  }

  .input-edit-profile label{
    color: #555;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 5px;
  }